// Get dom element selector.
const dom = {
	select: document.querySelector.bind(document),
	selectAll: document.querySelectorAll.bind(document)
};

export {dom};

// Get breakpoints from CSS.
const breakpoint = () => {
	const b = window.getComputedStyle(
		dom.select('#breakpoint'),
		':after'
	).getPropertyValue('content').replace(/"|'/g, '');

	return b;
};

export {breakpoint};

// Throttle triggered Events
// Wrap your actions in this function to throttle the frequency of firing them off
const throttle = (() => {
	const timers = {};
	return (callback, ms, uniqueId) => {
		if (!uniqueId) {
			uniqueId = 'Don\'t call this twice without a uniqueId';
		}
		if (timers[uniqueId]) {
			clearTimeout(timers[uniqueId]);
		}
		timers[uniqueId] = setTimeout(callback, ms);
	};
})();

export {throttle};


const matchesMethod = (() => {
	const ElemProto = window.Element.prototype;

	// Check for the standard method name first
	if (ElemProto.matches) {
		return 'matches';
	}

	// Check un-prefixed
	if (ElemProto.matchesSelector) {
		return 'matchesSelector';
	}

	// Check vendor prefixes
	const prefixes = ['webkit', 'moz', 'ms', 'o'];

	for (let i = 0; i < prefixes.length; i++) {
		const prefix = prefixes[i];
		const method = prefix + 'MatchesSelector';

		if (ElemProto[method]) {
			return method;
		}
	}
})();

export function matchesSelector(elem, selector) {
	return elem[matchesMethod](selector);
}


const twoDigits = n => {
	return (n < 10 ? '0' : '') + n;
};

export {twoDigits};
