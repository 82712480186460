import 'intersection-observer';
import anime from 'animejs';
import Flickity from 'flickity';
import fizzyUIUtils from 'fizzy-ui-utils';
import {dom, breakpoint, throttle, matchesSelector, twoDigits} from './_utils';

/* eslint-env browser */

(() => {
	'use strict';

	if (!('querySelector' in document && 'localStorage' in window && 'addEventListener' in window)) {
		return;
	}

	let viewport = {};

	// Initialise App and Document
	const app = dom.select('#app');
	const doc = dom.select('#document');

	// Add (initial) progressive enhancement semantics
	doc.classList.add('enhanced');

	// Global navigation variables
	const globalNav = dom.select('.global-nav');
	const globalNavFixed = 'global-nav--fixed';
	const globalNavHidden = 'global-nav--hidden';
	const globalNavVisible = 'global-nav--visible';
	const globalHeaderButton = dom.select('.global-header-button');

	function navigationSticky() {
		let globalNavOffset;

		if (app.classList.contains('logged-in')) {
			globalNavOffset = 435;
		} else {
			globalNavOffset = 405;
		}

		window.addEventListener('scroll', function () {
			// Navigation fixed on scroll.
			if (this.pageYOffset > globalNavOffset) {
				globalNav.classList.add(globalNavFixed);
			} else {
				globalNav.classList.remove(globalNavFixed);
			}
		});
	}

	function navigationToggle() {
		// Add (initial) button semantics
		globalHeaderButton.setAttribute('aria-haspopup', true);
		globalHeaderButton.setAttribute('aria-expanded', false);

		const toggle = () => {
			const toggled = globalHeaderButton.getAttribute('aria-expanded');

			if (toggled === 'false') {
				globalHeaderButton.setAttribute('aria-expanded', true);
				globalNav.classList.add(globalNavVisible);

				anime({
					targets: '#line-3',
					transform: ['matrix(1,0,0,1,0,0)', 'matrix(0,0,0,1,12,0)'],
					easing: 'easeInOutSine',
					duration: 800
				});

				anime({
					targets: '#line-1',
					transform: ['matrix(1,0,0,1,0,0)', 'matrix(0,0,0,1,12,0)'],
					easing: 'easeInOutSine',
					duration: 800
				});
			} else {
				globalHeaderButton.setAttribute('aria-expanded', false);
				globalNav.classList.remove(globalNavVisible);

				anime({
					targets: '#line-3',
					transform: ['matrix(0,0,0,1,12,0)', 'matrix(1,0,0,1,0,0)'],
					easing: 'easeInOutSine',
					duration: 800
				});

				anime({
					targets: '#line-1',
					transform: ['matrix(0,0,0,1,12,0)', 'matrix(1,0,0,1,0,0)'],
					easing: 'easeInOutSine',
					duration: 800
				});
			}
		};

		globalHeaderButton.addEventListener('click', toggle);
	}

	function navigationAutoHide() {
		const globalFooter = dom.select('.global-footer');
		const config = {
			rootMargin: '0px 0px -150px'
		};

		let isLeaving = false;
		const observer = new IntersectionObserver((entries => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					isLeaving = true;
					globalNav.classList.add(globalNavHidden);
				} else if (isLeaving) {
					isLeaving = false;
					globalNav.classList.remove(globalNavHidden);
				}
			});
		}), config);
		observer.observe(globalFooter);
	}

	function fullscreenToggle() {
		const fsButton = dom.select('.fullscreen-hit-area');

		// Add (initial) button semantics
		fsButton.setAttribute('aria-pressed', false);

		const toggle = () => {
			const toggled = fsButton.getAttribute('aria-pressed');

			if (toggled === 'false') {
				fsButton.setAttribute('aria-pressed', true);
				doc.classList.add('fullscreen');

				anime({
					targets: '#arrow-1',
					points: '4 14 10 14 10 20',
					easing: 'easeInOutSine',
					duration: 800
				});

				anime({
					targets: '#arrow-2',
					points: '20 10 14 10 14 4',
					easing: 'easeInOutSine',
					duration: 800
				});
			} else {
				fsButton.setAttribute('aria-pressed', false);
				doc.classList.remove('fullscreen');

				anime({
					targets: '#arrow-1',
					points: '15 3 21 3 21 9',
					easing: 'easeInOutSine',
					duration: 800
				});

				anime({
					targets: '#arrow-2',
					points: '9 21 3 21 3 15',
					easing: 'easeInOutSine',
					duration: 800
				});
			}
		};

		fsButton.addEventListener('click', toggle);
	}

	// Content directions
	function directions() {
		const directionsElement = dom.select('.directions');
		const directionsFollow = dom.select('.directions-scroll');

		if (!directionsElement) {
			return;
		}

		// Set up variables for following line
		const directionsHeight = directionsElement.offsetHeight;
		const followHeight = directionsFollow.offsetHeight;
		const windowHeight = window.innerHeight;

		// Set up hover detection for directions element
		const directionsItems = dom.selectAll('[data-hover-direction]');

		for (let i = 0; i < directionsItems.length; i++) {
			directionsItems[i].addEventListener('mouseover', hover);
			directionsItems[i].addEventListener('mouseleave', leave);
		}

		function hover() {
			const id = this.dataset.hoverDirection;
			const bg = dom.select('[data-direction="' + id + '"]');

			bg.style.opacity = '1';

			for (let i = 0; i < directionsItems.length; i++) {
				if (this !== directionsItems[i]) {
					directionsItems[i].style.opacity = '0.2';
				}
			}
		}

		function leave() {
			const id = this.dataset.hoverDirection;
			const bg = dom.select('[data-direction="' + id + '"]');

			bg.style.opacity = '0';

			for (let i = 0; i < directionsItems.length; i++) {
				directionsItems[i].style.opacity = '1';
			}
		}

		window.addEventListener('resize', () => {
			throttle(() => {
				viewport = breakpoint();

				if (viewport !== 'medium' || viewport !== 'large') {
					for (let i = 0; i < directionsItems.length; i++) {
						directionsItems[i].removeEventListener('mouseover', hover);
						directionsItems[i].removeEventListener('mouseleave', leave);
					}
				}

				if (viewport === 'medium' || viewport === 'large') {
					for (let i = 0; i < directionsItems.length; i++) {
						directionsItems[i].addEventListener('mouseover', hover);
						directionsItems[i].addEventListener('mouseleave', leave);
					}
				}
			}, 100, 'resizeDirections');
		});

		// Content directions line follows in element.
		window.addEventListener('scroll', () => {
			const pos = window.pageYOffset;
			// FollowHeight only needed for effect and less accuracy of top
			const top = directionsElement.getBoundingClientRect().top + window.scrollY - followHeight;

			// Check if element totally above or totally below viewport
			if (top + directionsHeight - followHeight < pos || top > pos + windowHeight) {
				return;
			}

			const offset = parseInt(window.pageYOffset - top, 10);

			if (offset > 0) {
				directionsFollow.style.transform = 'translate3d(0px, ' + offset + 'px, 1px)';
			}
		});
	}

	function carousel() {
		const carouselContainers = dom.selectAll('.carousel-images');

		if (carouselContainers) {
			for (let i = 0; i < carouselContainers.length; i++) {
				init(carouselContainers[i]);
			}
		}

		function init(container) {
			const items = container.querySelector('.carousel-items');

			if (items) {
				const flkty = new Flickity(items, {
					// freeScroll: true,
					contain: true,
					prevNextButtons: false,
					pageDots: false
				});

				const carouselNavigation = container.querySelector('.carousel-navigation');

				if (carouselNavigation) {
					const updateStatus = () => {
						const carouselPagination = container.querySelector('.carousel-pagination');
						const slide = twoDigits(flkty.selectedIndex + 1);
						const slides = twoDigits(flkty.slides.length);

						carouselPagination.innerHTML = `
							<span class="carousel-pagination-item--current">${slide}</span>
							<span class="carousel-pagination-item--divider">/</span>
							<span class="carousel-pagination-item--total">${slides}</span>
						`;
					};
					updateStatus();

					flkty.on('select', updateStatus);

					dom.select('.carousel-button--previous').addEventListener('click', () => {
						flkty.previous(true);
					});

					dom.select('.carousel-button--next').addEventListener('click', () => {
						flkty.next(true);
					});
				}
			}
		}
	}

	function carouselContent() {
		const utils = fizzyUIUtils;
		const carouselContainers = dom.selectAll('.carousel-content');

		if (carouselContainers) {
			for (let i = 0; i < carouselContainers.length; i++) {
				init(carouselContainers[i]);
			}
		}

		function init(container) {
			const items = container.querySelector('.carousel-items');

			if (items) {
				const flkty = new Flickity(items, {
					cellAlign: 'left',
					prevNextButtons: false,
					pageDots: false
				});

				// Navigation elements
				const nav = container.querySelector('.carousel-navigation');

				if (nav) {
					const buttons = utils.makeArray(nav.children);

					// Update buttons on select
					flkty.on('select', () => {
						const previousSelectedButton = nav.querySelector('.is-selected');
						const selectedButton = nav.children[flkty.selectedIndex];
						previousSelectedButton.classList.remove('is-selected');
						selectedButton.classList.add('is-selected');
					});

					// Select on click
					nav.addEventListener('click', event => {
						if (!matchesSelector(event.target, '.carousel-link')) {
							return;
						}
						const index = buttons.indexOf(event.target);
						flkty.select(index);
					});
				}
			}
		}
	}

	// App Event Listeners
	window.addEventListener('load', () => {
		viewport = breakpoint();

		navigationToggle();
		fullscreenToggle();

		if (viewport === 'medium' || viewport === 'large') {
			directions();
		}

		if (viewport === 'large') {
			navigationSticky();
			navigationAutoHide();
		}

		carousel();
		carouselContent();

		// DEBUG ONLY console.log('bp: ' + viewport);
	});

	window.addEventListener('resize', () => {
		throttle(() => {
			viewport = breakpoint();

			if (viewport === 'large') {
				navigationSticky();
				navigationAutoHide();
			}
			// DEBUG ONLY console.log('bp: ' + viewport);
		}, 100, 'resizeNavigationEvent');
	});
})();
